import { configureStore } from '@reduxjs/toolkit'
import admin from './slices/admin'
import notifications from './slices/notifications'
import customer from './slices/customer'
import layout from './slices/layout'
import alerts from './slices/alerts'
import confirmPopin from './slices/confirmPopin'
import footer from './slices/footer'
import cartType from './slices/cartType'
import cart from './slices/cart'
import language from './slices/language'
import resetState from './slices/resetState'
import popinInformation from './slices/popinInformation'
import mountMessage from './slices/mountMessage'

export default configureStore({
    reducer: {
        admin,
        customer,
        footer,
        layout,
        alerts,
        confirmPopin,
        cartType,
        language,
        cart,
        notifications,
        resetState,
        popinInformation,
        mountMessage,
    },
})
