import { createSlice } from '@reduxjs/toolkit'

export const mountMessageSlice = createSlice({
    name: 'mountMessage',
    initialState: {
        message: null,
        pending: false,
        language: null,
    },
    reducers: {
        setMountMessage: (state, action) => {
            state.message = action.payload.message
            state.language = action.payload.language
            state.pending = false
        },
        setMessagePending: state => {
            state.pending = true
        },
    },
})

export const { setMountMessage, setMessagePending } = mountMessageSlice.actions

export default mountMessageSlice.reducer
